import {
  ChangeEventHandler,
  HTMLInputTypeAttribute,
  KeyboardEventHandler,
} from 'react';
import styled from '@emotion/styled';

type TextInputProps = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  value?: string;
  placeholder?: string;
  required?: boolean;
  type?: HTMLInputTypeAttribute;
  className?: string;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  id?: string;
  hasError?: boolean;
  autoComplete?: string;
  maxLength?: number;
};

const TextInput = ({
  onChange,
  onKeyDown,
  value = null,
  placeholder = null,
  required = false,
  className = null,
  type = 'text',
  id = null,
  hasError = false,
  autoComplete,
  maxLength,
}: TextInputProps) => (
  <StyledInput
    id={id}
    required={required}
    type={type}
    value={value}
    onChange={onChange}
    onKeyDown={onKeyDown}
    placeholder={placeholder}
    className={className}
    hasError={hasError}
    autoComplete={autoComplete}
    maxLength={maxLength}
  />
);

export default TextInput;

const StyledInput = styled.input<{ hasError: boolean }>`
  padding: 12px 15px;
  display: block;
  ${props =>
    props.hasError
      ? `border: ${props.theme.color.alertRed} 1px solid; border-radius: 2px;`
      : 'border: none;'}

  height: 48px;
  width: 100%;
  font-size: 16px;
  outline: none;
  resize: none;

  ::placeholder {
    font-size: 14px;
    color: ${props => props.theme.color.midGray};
  }
`;
