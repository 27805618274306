import { useState } from 'react';
import { shallowEqual } from 'react-redux';
import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { SubscriptionPlans } from '@app/api/resources/SubscriptionPlans';
import { Translate } from '@app/api/utility-types';

import { RootState } from '@app/reducers';
import { OnboardingContext } from '@app/reducers/AppStateReducer';

import useSnowplowClickTracker from '@app/hooks/snowplow/useSnowplowClickTracker';
import useAppSelector from '@app/hooks/utils/useAppSelector';

import EmailForm from '@app/components/EmailForm';
import OnboardingOfferSignUpCTA from '@app/components/onboarding/OnboardingOfferSignUpCTA';
import OnboardingSignupCtaButtonContainer from '@app/components/onboarding/OnboardingSignupCtaButtonContainer';
import { SplashFormButton } from '@app/components/splash/SplashFormButton';

type SplashFormCTAProps = {
  snowplowElement?: 'cta_top' | 'cta_bottom' | 'cta_film' | 'cta_mubigo';
  forcePlan?: string;
  ignorePromo?: boolean;
  hidePrice?: boolean;
  ctaButtonTextOverride?: string;
  formTitleTextOverride?: string;
  className?: string;
  redirectOnCloseUrl?: string;
  welcomeImgUrl?: string;
  originUrl?: string;
  textColor?: 'white' | 'black';
  additionalButtonCTA?: JSX.Element;
};

const SplashFormCTA = ({
  snowplowElement,
  forcePlan = null,
  ignorePromo = false,
  hidePrice = false,
  redirectOnCloseUrl = null,
  ctaButtonTextOverride,
  formTitleTextOverride,
  className,
  textColor = 'white',
  additionalButtonCTA = null,
}: SplashFormCTAProps) => {
  const [emailAddress, setEmailAddress] = useState('');
  const [isCtaDisabled, setIsCtaDisabled] = useState(false);
  const { t } = useTranslation('common');
  const trackSnowplowClickEvent = useSnowplowClickTracker();

  const { trialsEnabled, subscriptionPlans } = useAppSelector(
    (state: RootState) => ({
      trialsEnabled: state.appState.trialsEnabled,
      subscriptionPlans: state.user.subscriptionPlans,
    }),
    shallowEqual,
  );

  const triggerOnboardingModal = async (
    showModal: (onboardingContext: OnboardingContext) => void,
    context: OnboardingContext,
  ) => {
    setIsCtaDisabled(true);
    trackSnowplowClickEvent({
      data: {
        clickType: 'cta_trial_start',
        element: snowplowElement,
      },
    });

    // eslint-disable-next-line no-constant-condition
    showModal(context);

    setIsCtaDisabled(false);
  };

  return (
    <Container>
      <OnboardingSignupCtaButtonContainer
        ignorePromo={ignorePromo}
        forcePlan={forcePlan}
        redirectUrl={redirectOnCloseUrl}
        initialEmail={emailAddress}
        isSplashFormCTA
        hidePrice={hidePrice}
      >
        {({
          showModal,
          context,
          ctaButtonText,
          showEmailFormCTA,
          isOnboardingOfferCTA,
        }) => {
          if (showEmailFormCTA) {
            const formTitleText = getFormTitleText(
              t,
              hidePrice,
              trialsEnabled,
              subscriptionPlans,
              forcePlan,
            );

            return (
              <EmailForm
                className={className}
                isOnboardingOfferCTA={isOnboardingOfferCTA}
                formTitleText={formTitleTextOverride || formTitleText}
                emailAddress={emailAddress}
                setEmailAddress={setEmailAddress}
                submitForm={() => triggerOnboardingModal(showModal, context)}
                ctaButtonText={ctaButtonTextOverride || ctaButtonText}
                textColor={textColor}
                isDisabled={isCtaDisabled}
              />
            );
          }
          return isOnboardingOfferCTA ? (
            <OnboardingOfferSignUpCTA
              ctaButtonText={ctaButtonTextOverride || ctaButtonText}
              textColor={textColor}
              clickAction={() => showModal(context)}
              additionalButtonCTA={additionalButtonCTA}
            />
          ) : (
            <SignupButton clickAction={() => showModal(context)}>
              {ctaButtonTextOverride || ctaButtonText}
            </SignupButton>
          );
        }}
      </OnboardingSignupCtaButtonContainer>
    </Container>
  );
};

const getFormTitleText = (
  t: Translate,
  hidePrice: boolean,
  trialsEnabled: boolean,
  subscriptionPlans: SubscriptionPlans,
  forcePlan: string,
) => {
  let formTitleText = t('common.subscribe_ctas.start_watching_for_only', {
    amount: getPlanPrice(subscriptionPlans, forcePlan),
  });

  if (trialsEnabled) {
    formTitleText = t('common:common.subscribe_ctas.try_7_days_free');
  } else if (hidePrice) {
    formTitleText = t('common:common.subscribe_ctas.enter_email');
  }

  return formTitleText;
};

const getPlanPrice = (
  subscriptionPlans: SubscriptionPlans,
  forcePlan: string,
) => {
  if (subscriptionPlans?.[forcePlan]?.price) {
    return subscriptionPlans?.[forcePlan]?.price;
  }
  if (subscriptionPlans?.month?.price) {
    return subscriptionPlans?.month?.price;
  }
  return '';
};

const Container = styled.div``;

const SignupButton = styled(SplashFormButton)``;

export default SplashFormCTA;
