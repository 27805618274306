import { color as themeColors } from '@app/themes/mubi-theme';

import Svg from '@app/components/icons/Svg';

type WidthColorProps = {
  width?: string;
  color?: string;
};

export const PlayButtonIcon = ({
  color = themeColors.white,
  width = '14px',
}: WidthColorProps) => (
  <Svg viewBox="0 0 14 15" width={width}>
    <path d="M14.029 7.087l-14 7v-14z" fill={color} fillRule="evenodd" />
  </Svg>
);

export const UnMutedButtonIcon = ({
  color = themeColors.white,
  width = '23px',
}: WidthColorProps) => (
  <Svg viewBox="0 0 23 16" width={width}>
    <g fill={color} fillRule="evenodd">
      <path d="M15.18 3.16a.57.57 0 0 0-.02.81 5.79 5.79 0 0 1 .1 8.06c-.22.24-.21.6.02.82.12.1.26.15.4.15.16 0 .31-.06.43-.18A6.95 6.95 0 0 0 16 3.18a.59.59 0 0 0-.82-.02" />
      <path d="M19.3.2a.88.88 0 0 0-1.05-.05c-.3.22-.33.6-.06.84a9.56 9.56 0 0 1 3.32 7.06 9.54 9.54 0 0 1-3.22 6.97c-.27.24-.23.62.08.84.14.1.31.14.48.14.21 0 .42-.07.57-.21A10.6 10.6 0 0 0 23 8.05c0-2.9-1.31-5.68-3.7-7.85M11.33 16a.72.72 0 0 1-.37-.1L5.6 11.2H.67c-.37 0-.67-.26-.67-.59v-5.9c0-.32.3-.59.67-.59H5.6L10.96.1c.2-.12.47-.13.69-.03.21.1.35.3.35.52V15.4c0 .22-.14.42-.35.52-.1.05-.2.07-.32.07" />
    </g>
  </Svg>
);

export const MutedButtonIcon = ({
  color = themeColors.white,
  width = '24px',
}: WidthColorProps) => (
  <Svg viewBox="0 0 24 16" width={width}>
    <g fill={color} fillRule="evenodd">
      <path d="M11.33 16a.72.72 0 0 1-.37-.1L5.6 11.2H.67c-.37 0-.67-.26-.67-.59v-5.9c0-.32.3-.59.67-.59H5.6L10.96.1c.2-.12.47-.13.69-.03.21.1.35.3.35.52V15.4c0 .22-.14.42-.35.52-.1.05-.2.07-.32.07" />
      <g transform="translate(15 2)" stroke={color} strokeWidth=".1">
        <rect
          transform="rotate(-38 4.09 6)"
          x="3.59"
          width="1"
          height="12"
          rx="8"
        />
        <rect
          transform="scale(1 -1) rotate(-38 -13.34 0)"
          x="3.59"
          width="1"
          height="12"
          rx="8"
        />
      </g>
    </g>
  </Svg>
);

export const FilmPlayerBackButtonIcon = ({
  color = themeColors.white,
  width = '18px',
}: WidthColorProps) => (
  <Svg viewBox="0 0 18 41" width={width} style={{ cursor: 'pointer' }}>
    <path
      d="M1.3 21.04c-.5-.74-.48-1.7.03-2.42L14.06.44c.33-.46.98-.58 1.45-.27l2.03 1.33c.48.32.6.94.28 1.4L5.93 19.87l11.9 17.69a1 1 0 0 1-.31 1.4l-2.05 1.29c-.49.3-1.13.17-1.45-.3L1.31 21.05"
      fill={color}
      fillRule="evenodd"
    />
  </Svg>
);

export const SubtitleIcon = ({
  color = themeColors.white,
  width = '23px',
}: WidthColorProps) => (
  <Svg viewBox="0 0 23 23" width={width}>
    <path
      d="M.67 0C.27 0 0 .36 0 .78v21.46c0 .28.12.53.34.66.11.07.22.1.35.1.12 0 .24-.02.35-.09l7.14-4.49h14.06a.8.8 0 00.76-.78V.78a.8.8 0 00-.76-.78H.67zM17.5 13.32H5.51c-.37 0-.67-.28-.67-.6 0-.34.3-.61.67-.61h11.98c.37 0 .67.27.67.6 0 .33-.3.6-.67.6zm0-3.64H5.51c-.37 0-.67-.27-.67-.6 0-.34.3-.6.67-.6h11.98c.37 0 .67.26.67.6 0 .33-.3.6-.67.6zM5.51 6.05c-.37 0-.67-.27-.67-.6 0-.34.3-.6.67-.6h11.98c.37 0 .67.26.67.6 0 .33-.3.6-.67.6H5.51z"
      fill={color}
      fillRule="evenodd"
    />
  </Svg>
);

export const FullscreenExpandButtonIcon = ({
  color = themeColors.white,
  width = '16px',
}: WidthColorProps) => (
  <Svg viewBox="0 0 16 16" width={width}>
    <g
      fill="none"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M.5 9.5v6h6M1 15l5.5-5.5" />
      <g>
        <path d="M15.5 6.5v-6h-6M15 1L9.5 6.5" />
      </g>
    </g>
  </Svg>
);

export const FullscreenCompressButtonIcon = ({
  color = themeColors.white,
  width = '16px',
}: WidthColorProps) => (
  <Svg viewBox="0 0 16 16" width={width}>
    <g
      fill="none"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M9 1v6h6M9.5 6.5l6-6" />
      <g>
        <path d="M7 15V9H1M6.5 9.5l-6 6" />
      </g>
    </g>
  </Svg>
);

export const PauseButtonIcon = ({
  color = themeColors.white,
  width = '21px',
}: WidthColorProps) => (
  <Svg viewBox="0 0 21 28" width={width}>
    <g fill={color} fillRule="evenodd">
      <rect width="6.36" height="28" rx="1" />
      <rect x="13.77" width="6.36" height="28" rx="1" />
    </g>
  </Svg>
);

export const PlayerBackIcon = ({
  color = themeColors.white,
  width = '48px',
}: WidthColorProps) => (
  <Svg viewBox="0 0 48 48" width={width} style={{ cursor: 'pointer' }}>
    <g transform="translate(1 1)" stroke={color} fill="none" fillRule="evenodd">
      <circle cx="23" cy="23" r="23" />
      <path d="M27.12 14L18 23l9.12 9" />
    </g>
  </Svg>
);

export const PlayerQuestionIcon = ({
  color = themeColors.white,
  width = '16px',
}: WidthColorProps) => (
  <Svg viewBox="0 0 16 16" width={width}>
    <circle cx="8" cy="8" r="7.5" fill="none" stroke={color} />
    <path
      fill={color}
      d="M7.16 10.25h1.76V12H7.16v-1.75zm-.98-6.8A3.13 3.13 0 017.89 3c.9 0 1.64.22 2.23.65.59.44.88 1.08.88 1.94 0 .52-.13.96-.38 1.32-.15.22-.44.5-.87.83l-.42.34c-.23.18-.38.39-.45.63-.05.16-.08.4-.08.72H7.2c.02-.69.09-1.16.2-1.42.1-.26.36-.56.78-.9l.44-.34c.14-.1.25-.23.34-.35.16-.23.24-.47.24-.74 0-.3-.1-.58-.27-.83-.17-.26-.5-.38-.96-.38-.46 0-.78.15-.97.46-.2.31-.29.64-.29.97H5c.05-1.15.44-1.96 1.18-2.44z"
    />
  </Svg>
);

export const ShareArrowIcon = ({
  color = themeColors.white,
  width = '24px',
}: WidthColorProps) => (
  <Svg viewBox="0 0 24 14" width={width}>
    <path
      d="M10.23 0l13.03 7-13.03 7V9.5H6.65c-3.07 0-5.29 1-6.65 3C0 12.5.51 4 10.23 4V0z"
      fill={color}
      fillRule="evenodd"
    />
  </Svg>
);

export const Minus15SecondIcon = ({
  color = themeColors.white,
  width = '27px',
}: WidthColorProps) => (
  <Svg viewBox="0 0 27 30" width={width}>
    <path
      d="M13.5 2.923c7.456 0 13.5 6.062 13.5 13.539C27 23.939 20.956 30 13.5 30S0 23.939 0 16.462h1.5l.004.266c.162 6.487 5.47 11.695 11.996 11.695 6.627 0 12-5.372 12-12 0-6.627-5.373-12-12-12v-1.5zm5.184 8.68v1.516h-3.723l-.408 2.193.12-.125c.172-.16.387-.297.645-.41a2.7 2.7 0 0 1 1.084-.21c.638 0 1.169.152 1.594.454.425.303.748.7.969 1.192a3.87 3.87 0 0 1 .331 1.604c0 .659-.134 1.246-.401 1.76-.268.515-.642.92-1.122 1.218-.48.298-1.035.447-1.664.447-.655 0-1.218-.122-1.69-.365a2.784 2.784 0 0 1-1.109-1.009 3.172 3.172 0 0 1-.465-1.455h1.594l.04.162c.092.317.266.585.52.806.298.257.668.386 1.11.386.476 0 .865-.185 1.166-.555.302-.37.453-.83.453-1.381 0-.569-.15-1.02-.453-1.354a1.472 1.472 0 0 0-1.14-.501c-.383 0-.715.1-.995.298-.28.198-.48.446-.6.744h-1.568l.765-5.415h4.947zm-7.51 0v9.477H9.48v-7.5l-1.466.365v-1.327l2.154-1.015h1.008zM13.5 0v7.977L8.65 4.09v3.887L3.614 4.09 8.651 0v3.989L13.5 0z"
      fill={color}
      fillRule="evenodd"
    />
  </Svg>
);

export const Plus15SecondIcon = ({
  color = themeColors.white,
  width = '27px',
}: WidthColorProps) => (
  <Svg viewBox="0 0 27 30" width={width}>
    <path
      d="M18.35 0l5.037 4.09-5.038 3.887V4.09L13.5 7.977V4.423l-.305.004C6.81 4.587 1.663 9.733 1.504 16.118l-.004.305c0 6.628 5.373 12 12 12 6.525 0 11.834-5.208 11.996-11.695l.004-.267H27C27 23.94 20.956 30 13.5 30S0 23.939 0 16.462 6.044 2.923 13.5 2.923V0l4.85 3.989V0zm.495 11.603v1.516h-3.723l-.408 2.193.12-.125c.172-.16.387-.297.645-.41a2.7 2.7 0 0 1 1.084-.21c.637 0 1.168.152 1.593.454.425.303.748.7.97 1.192a3.87 3.87 0 0 1 .331 1.604c0 .659-.134 1.246-.402 1.76-.267.515-.641.92-1.122 1.218-.48.298-1.035.447-1.664.447-.654 0-1.217-.122-1.689-.365a2.784 2.784 0 0 1-1.11-1.009 3.172 3.172 0 0 1-.465-1.455H14.6l.04.162c.092.317.266.585.521.806.298.257.667.386 1.11.386.475 0 .864-.185 1.166-.555.302-.37.453-.83.453-1.381 0-.569-.151-1.02-.453-1.354a1.472 1.472 0 0 0-1.141-.501c-.383 0-.714.1-.995.298-.28.198-.48.446-.599.744h-1.568l.765-5.415h4.947zm-7.51 0v9.477H9.64v-7.5l-1.466.365v-1.327l2.155-1.015h1.007z"
      fill={color}
      fillRule="evenodd"
    />
  </Svg>
);
