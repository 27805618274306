import { ReactNode, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { createPopper } from '@popperjs/core';

import { TooltipOptionsProps } from '@app/components/tool-tip/Tooltip';

type TooltipPositionProps = TooltipOptionsProps & {
  uniqId?: string | number;
  children: ReactNode;
  message?: string | ReactNode;
  className?: string;
};

const TooltipPosition = ({
  uniqId = null,
  children,
  message = '',
  className = null,
  fontSize = '12px',
  padding = '5px',
  placement = 'top',
  zIndex = '1',
  overflowElementId = null,
  backgroundColor = null,
  boxShadow = '9px 10px 27px -8px rgba(0, 0, 0, 0.75)',
}: TooltipPositionProps) => {
  const [popperInstance, setPopperInstance] = useState(null);

  useEffect(() => {
    if (!popperInstance) {
      createPopperTooltip();
    } else {
      popperInstance.update();
    }

    return () => {
      if (popperInstance) {
        popperInstance.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (popperInstance && shouldRepositionTooltip()) {
      popperInstance.update();
    }
  });

  const shouldRepositionTooltip = () => {
    if (typeof message === 'object') {
      return true;
    }
    if (typeof message === 'string' && message.length > 0) {
      return true;
    }

    return false;
  };

  const createPopperTooltip = () => {
    const popcorn = document.querySelector(`#button-${uniqId}`);
    const tooltip = document.querySelector(`#tooltip-${uniqId}`) as HTMLElement;
    const overflowElement = document.querySelector(`#${overflowElementId}`);

    const modifiers = [
      {
        name: 'offset',
        options: {
          offset: [0, 12],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          boundary: overflowElement,
        },
      },
    ];

    const popperInstanceNew = createPopper(popcorn, tooltip, {
      modifiers,
      placement,
    });

    setPopperInstance(popperInstanceNew);
  };

  return (
    <>
      <span id={`button-${uniqId}`}>{children}</span>
      <Container
        id={`tooltip-${uniqId}`}
        isTooltipMessage={message}
        zIndex={zIndex}
        backgroundColor={backgroundColor}
        boxShadow={boxShadow}
      >
        <Message
          fontSize={fontSize}
          padding={padding}
          className={className}
          backgroundColor={backgroundColor}
        >
          {message}
        </Message>
        <Arrow id="arrow" data-popper-arrow />
      </Container>
    </>
  );
};

export default TooltipPosition;

type ContainerProps = {
  isTooltipMessage: string | ReactNode;
  zIndex: string;
  backgroundColor: string;
  boxShadow: string;
};

type MessageProps = {
  fontSize: string;
  padding: string;
  backgroundColor: string;
};

const Container = styled.span<ContainerProps>`
  transition: opacity 0.2s linear;
  opacity: 0;
  z-index: ${props => props.zIndex};

  ${props =>
    props.isTooltipMessage
      ? 'visibility: visible; opacity: 1;'
      : 'visibility: hidden; width: 0;height: 0;'}
  box-shadow: ${props => props.boxShadow};

  &[data-popper-placement^='top'] > #arrow {
    bottom: -9px;
    border-top: 10px solid
      ${props =>
        props.backgroundColor
          ? props.backgroundColor
          : props.theme.color.white};
  }

  &[data-popper-placement^='bottom'] > #arrow {
    top: -9px;
    border-bottom: 10px solid
      ${props =>
        props.backgroundColor
          ? props.backgroundColor
          : props.theme.color.white};
  }
`;

const Message = styled.div<MessageProps>`
  font-family: ${props => props.theme.font.body};
  font-size: ${props => props.fontSize};
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : props.theme.color.white};
  padding: ${props => props.padding};
  color: ${props => props.theme.color.black};

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    white-space: nowrap;
  }
`;

const Arrow = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
`;
