import { shallowEqual } from 'react-redux';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { getOnboardingOfferHeading } from '@app/services/subscribe-cta';

import useAppSelector from '@app/hooks/utils/useAppSelector';

type OnboardingOfferHeadingProps = {
  textColor?: 'white' | 'black';
};

const OnboardingOfferHeading = ({ textColor }: OnboardingOfferHeadingProps) => {
  const { t } = useTranslation('common');

  const { promoEndsText, onboardingPromo } = useAppSelector(
    state => ({
      onboardingPromo: state.appState.onboardingConfig?.promo,
      promoEndsText: state.appState.onboardingConfig?.offer_ends_on_text,
    }),
    shallowEqual,
  );

  const router = useRouter();
  const isSplashFormCTA = router.pathname === '/[vanityPath]';

  const { title = null, subtitle = null } = getOnboardingOfferHeading(
    t,
    onboardingPromo,
  );

  return (
    <Container>
      {title && <Title isSplashFormCTA={isSplashFormCTA}>{title}</Title>}
      {subtitle && (
        <Subtitle isSplashFormCTA={isSplashFormCTA}>{subtitle}</Subtitle>
      )}
      {promoEndsText && (
        <PromoEndsText color={textColor}>{promoEndsText}</PromoEndsText>
      )}
    </Container>
  );
};

export default OnboardingOfferHeading;

const Container = styled.div`
  margin-bottom: 12px;
`;

const sharedStyle = props => css`
  color: ${props.theme.color.yellowOnboardingOffer};
  text-transform: uppercase;
`;
const Title = styled.div<{
  isSplashFormCTA: boolean;
}>`
  ${sharedStyle};
  font-size: 30px;
  font-weight: bold;
  line-height: 1;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    font-size: 32px;
  }

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    font-size: ${props => (props.isSplashFormCTA ? '42px' : '')};
  }
`;

const Subtitle = styled.div<{
  isSplashFormCTA: boolean;
}>`
  ${sharedStyle};
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    font-size: ${props => (props.isSplashFormCTA ? '24px' : '')};
    line-height: ${props => (props.isSplashFormCTA ? '30px' : '')};
  }
`;

const PromoEndsText = styled.div<{
  color: 'white' | 'black';
}>`
  color: ${props =>
    props.color === 'white' ? props.theme.color.white : 'inherit'};

  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
`;
