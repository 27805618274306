import styled from '@emotion/styled';

import { color as themeColors } from '@app/themes/mubi-theme';

import { PlayButtonIcon } from '@app/components/icons/PlayerIcons';
import OnboardingOfferHeading from '@app/components/onboarding/OnboardingOfferHeading';
import { SplashFormButton } from '@app/components/splash/SplashFormButton';

type OnboardingOfferSignUpCTAProps = {
  clickAction?: () => void;
  ctaButtonText: string;
  textColor?: 'white' | 'black';
  additionalButtonCTA?: JSX.Element;
};

const OnboardingOfferSignUpCTA = ({
  textColor,
  clickAction,
  ctaButtonText,
  additionalButtonCTA = null,
}: OnboardingOfferSignUpCTAProps) => {
  return (
    <>
      <OnboardingOfferHeading textColor={textColor} />
      <ButtonCTAContainer>
        <SignupButton clickAction={clickAction} isOnboardingOfferCTA>
          <PlayIconContainer>
            <PlayButtonIcon width="100%" color={themeColors.black} />
          </PlayIconContainer>

          {ctaButtonText}
        </SignupButton>
        {additionalButtonCTA}
      </ButtonCTAContainer>
    </>
  );
};

export default OnboardingOfferSignUpCTA;

const SignupButton = styled(SplashFormButton)`
  border-radius: 65px;
  margin-right: 12px;
`;

const PlayIconContainer = styled.div`
  margin-right: 6px;
  width: 16px;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    width: 18px;
  }
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    width: 22px;
    margin-right: 8px;
  }
`;

const ButtonCTAContainer = styled.div`
  display: flex;
  align-items: center;
`;
