import { css } from '@emotion/react';
import styled from '@emotion/styled';

import MubiButton from '@app/components/buttons/MubiButton/MubiButton';

const onboardingCTAStyles = props =>
  props.isOnboardingOfferCTA
    ? css`
        background-color: ${props.theme.color.yellowOnboardingOffer};
        color: ${props.theme.color.black};
        border: 1px solid ${props.theme.color.yellowOnboardingOffer};

        &:hover:not(:disabled) {
          background-color: ${props.theme.color.yellow};
          color: ${props.theme.color.black};
          border-color: ${props.theme.color.yellow};
        }
      `
    : css``;

export const SplashFormButton = styled(MubiButton)<{
  noWrap?: boolean;
  isOnboardingOfferCTA?: boolean;
}>`
  font-size: 16px;
  line-height: 1;
  padding: 12px 16px;
  border-radius: 0;
  text-align: left;
  ${props => (props.noWrap ? 'white-space: nowrap;' : '')}
  ${onboardingCTAStyles};

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    padding: 13px 25px;
    font-size: 18px;
    line-height: 1.5;
  }
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    font-size: 24px;
  }
`;
